.container {
  width: 100%;
  min-width: 1180px;
  height: 40px;

  .banner {
    position: fixed;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 1180px;
    height: 40px;
    background-image: url('/images/banner/top_banner_bg.png');
    background-size: 100% 100%;
    cursor: pointer;
    .label1 {
      color: #fff;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      background: linear-gradient(to right, #ffefd4 0%, #ffd7a2 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .label2 {
      min-width: 400px;
      color: #fff;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      white-space: nowrap;
      text-align: center;
      text-shadow: 0 2px 4px #0154d5;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 118px;
      height: 32px;
      margin-left: 12px;
      background: linear-gradient(205deg, #fff2d6 0%, #fff2eb 100%);
      border: 2px solid #fff2eb;
      border-radius: 4px;
      cursor: pointer;
      .btnText {
        color: #e66d16;
        font-weight: 600;
        font-size: 15px;
        line-height: 16px;
      }
      .arrow {
        margin-left: 4px;
      }
    }
    .closeIconWrapper {
      position: absolute;
      right: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 20px;
      cursor: pointer;
    }
    // .closeIcon {
    // }
  }
}
