.container {
  width: 100%;
  height: 160px;
  background-image: url('/images/home/guide_bg@2x.png');
  background-size: 100% 100%;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1180px;
  max-width: 1180px;
  margin: auto;

  .leftContent {
    .title {
      margin-bottom: 16px;
      color: white;
      font-weight: bold;
      font-size: 24px;
      white-space: nowrap;
    }
    .desc {
      color: white;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .qrCode {
    display: flex;
    flex-direction: column;
    align-items: center;
    .qrCodeText {
      margin-top: 6px;
      color: white;
      font-weight: bold;
      font-size: 14px;
      white-space: nowrap;
    }
  }
}
