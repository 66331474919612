/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.BivvxlEJ {
  margin-top: 60px;
}
.kFNGfWZR {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 617px;
  height: 118px;
  color: #fff;
  background-image: url('/images/home/banner_nunber_bg@2x.png');
  background-size: 100%;
}
.GCnwY8gg {
  display: flex;
  align-items: stretch;
  height: 40px;
}
.GCnwY8gg .__1vOga5xb {
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 18px;
}
.GCnwY8gg .GF4vNgHQ {
  display: flex;
  align-items: center;
  margin: 0 30px;
  color: #f1f5fc;
  font-size: 48px;
  font-family: 'CondensedBold';
  letter-spacing: 10px;
}
.BivvxlEJ .LG_deFeZ {
  position: absolute;
  bottom: 40px;
  left: calc(50% - 72px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 144px;
  margin: auto;
  cursor: pointer;
}
.BivvxlEJ .LG_deFeZ .__4n1cfH0K {
  position: relative;
  width: 24px;
  height: 42px;
  background: #001a2f linear-gradient(transparent 0%, transparent 50%, #fff 50%, #fff 100%);
  background-size: 100% 200%;
  border-radius: 100px;
  animation: __8lqiaEpg 10s linear infinite, IEvYPpeI 10s ease-out infinite;
}
.BivvxlEJ .LG_deFeZ .__4n1cfH0K::before {
  width: 22px;
  height: 40px;
  background-color: #001a2f;
  border-radius: 100px;
}
.BivvxlEJ .LG_deFeZ .__4n1cfH0K::after {
  width: 4px;
  height: 4px;
  background-color: #fff;
  border-radius: 100%;
  animation: K21gQXjf 10s linear infinite;
}
.BivvxlEJ .LG_deFeZ .__4n1cfH0K::before,
.BivvxlEJ .LG_deFeZ .__4n1cfH0K::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: '';
}
@keyframes K21gQXjf {
  0% {
    transform: scale(1) translateY(-10px);
    opacity: 1;
  }
  6% {
    transform: scale(0.9) translateY(3px);
    opacity: 1;
  }
  14% {
    transform: scale(0.4) translateY(20px);
    opacity: 0;
  }
  15%,
  19% {
    transform: scale(0.4) translateY(-10px);
    opacity: 0;
  }
  28%,
  29.99% {
    transform: scale(1) translateY(-10px);
    opacity: 1;
  }
  30% {
    transform: scale(1) translateY(-10px);
    opacity: 1;
  }
  36% {
    transform: scale(0.9) translateY(2px);
    opacity: 1;
  }
  44% {
    transform: scale(0.4) translateY(20px);
    opacity: 0;
  }
  45%,
  49% {
    transform: scale(0.4) translateY(-20px);
    opacity: 0;
  }
  58%,
  59.99% {
    transform: scale(1) translateY(-10px);
    opacity: 1;
  }
  60% {
    transform: scale(1) translateY(-20px);
    opacity: 1;
  }
  66% {
    transform: scale(0.9) translateY(2px);
    opacity: 1;
  }
  74% {
    transform: scale(0.4) translateY(20px);
    opacity: 0;
  }
  75%,
  79% {
    transform: scale(0.4) translateY(-10px);
    opacity: 0;
  }
  88%,
  100% {
    transform: scale(1) translateY(-10px);
    opacity: 1;
  }
}
@keyframes __8lqiaEpg {
  0% {
    background-position: 0% 100%;
  }
  20% {
    background-position: 0% 0%;
  }
  21% {
    background-color: #001a2f;
  }
  29.99% {
    background-color: #fff;
    background-position: 0% 0%;
  }
  30% {
    background-color: #001a2f;
    background-position: 0% 100%;
  }
  50% {
    background-position: 0% 0%;
  }
  51% {
    background-color: #001a2f;
  }
  59% {
    background-color: #fff;
    background-position: 0% 0%;
  }
  60% {
    background-color: #001a2f;
    background-position: 0% 100%;
  }
  80% {
    background-position: 0% 0%;
  }
  81% {
    background-color: #001a2f;
  }
  90%,
  100% {
    background-color: #fff;
  }
}
@keyframes IEvYPpeI {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(4px);
  }
  30% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(4px);
  }
  60% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(4px);
  }
  90% {
    transform: translateY(0);
  }
}
.BivvxlEJ .LG_deFeZ .GXNLxQvX {
  margin-top: 12px;
  margin-bottom: 0;
  color: #fff;
  font-size: 12px;
  letter-spacing: 2px;
  animation: _QmsGCrv 10s ease-out infinite, wddYl7iN 10s ease-out infinite;
}
@keyframes _QmsGCrv {
  21% {
    color: #001a2f;
  }
  30% {
    color: #fff;
  }
  51% {
    color: #001a2f;
  }
  60% {
    color: #fff;
  }
  81% {
    color: #001a2f;
  }
  90% {
    color: #fff;
  }
}
@keyframes wddYl7iN {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(2px);
  }
  30% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(2px);
  }
  60% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(2px);
  }
  90% {
    transform: translateY(0);
  }
}
@media screen and (min-height: 900px) {
  .BivvxlEJ {
    margin-top: 110px;
  }
}
@media screen and (max-height: 900px) {
  .BivvxlEJ {
    margin-top: 85px;
  }
}
@media screen and (max-height: 800px) {
  .BivvxlEJ {
    margin-top: 60px;
  }
}
@media screen and (max-width: 1200px) {
  .BivvxlEJ {
    margin-top: 80px;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.KHI1MLCN {
  position: relative;
  height: 100vh;
  margin-top: -58px;
  background-color: #000e21;
}
.OBffARBa {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 716px;
  padding-bottom: 58px;
  background-color: #000e21;
}
.sofKrzkN {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.NFeQnlpX {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.KHI1MLCN .nI5tlmHH {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: auto !important;
  color: #fff;
}
.KHI1MLCN .nI5tlmHH .q4SpBQ9m {
  width: 520px;
}
.KHI1MLCN .nI5tlmHH .q4SpBQ9m .jSWO_rZ7 {
  width: 396px;
  height: 1px;
  margin-top: 16px;
  background: #fff;
  opacity: 0.34;
}
.KHI1MLCN .nI5tlmHH .q4SpBQ9m .SjbnJKZ6 {
  width: 238px;
  height: 3px;
  margin-top: -2px;
  background: #fff;
}
.KHI1MLCN .nI5tlmHH .q4SpBQ9m .__0D2RGL__ {
  margin-top: 14px;
}
.KHI1MLCN .nI5tlmHH .q4SpBQ9m .fLmAPo_m {
  width: 450px;
  height: 44px;
  margin: 24px 0 0;
  color: #fff;
  font-weight: normal;
  font-size: 44px;
  font-family: 'FZLTZCHJW';
  line-height: 44px;
}
.KHI1MLCN .nI5tlmHH .q4SpBQ9m .__8dnHytCQ {
  width: 440px;
  height: 56px;
  margin: 20px 0 0;
  color: #d8d8d8;
  font-weight: normal;
  font-size: 16px;
  font-family: 'FZLTXIHJW';
  line-height: 28px;
}
.KHI1MLCN .nI5tlmHH .q4SpBQ9m .VRnRSLS0 {
  width: 160px;
  height: 44px;
  margin-top: 72px;
  padding: 12px 34px 12px 38px;
  background: linear-gradient(270deg, #1a66ff 0%, #1a58ff 100%);
  border-radius: 6px;
  opacity: 1;
  transition: opacity 0.3s linear;
}
.KHI1MLCN .nI5tlmHH .q4SpBQ9m .VRnRSLS0 .wtPDJJvZ {
  margin-right: 4px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.KHI1MLCN .nI5tlmHH .q4SpBQ9m .VRnRSLS0 .wtPDJJvZ + span,
.KHI1MLCN .nI5tlmHH .q4SpBQ9m .VRnRSLS0 .wtPDJJvZ + div {
  position: absolute !important;
  width: 20px;
  height: 20px;
}
.KHI1MLCN .nI5tlmHH .q4SpBQ9m .VRnRSLS0:hover {
  opacity: 0.8;
}
.KHI1MLCN .nI5tlmHH .u0uxN_JK {
  margin-bottom: 30px;
  color: #fff;
  font-weight: normal;
  font-size: 40px;
  line-height: 85px;
  letter-spacing: 4px;
}
.KHI1MLCN .nI5tlmHH .upA4w8p4 {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  height: 20px;
  line-height: 20px;
}
.KHI1MLCN .nI5tlmHH .upA4w8p4:not(:last-child) {
  margin-bottom: 16px;
}
.KHI1MLCN .nI5tlmHH .upA4w8p4 .ZWM3EoSB {
  display: inline-block;
  margin-left: 8px;
  font-weight: 400;
  font-size: 16px;
}
.KHI1MLCN .Mz8_rNSw {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.KHI1MLCN .Mz8_rNSw video {
  width: 100%;
  outline: none;
  pointer-events: none;
}
@media screen and (max-width: 1200px) {
  .KHI1MLCN {
    position: relative;
    min-height: 716px;
    padding-bottom: 0;
  }
  .KHI1MLCN .nI5tlmHH {
    height: 106px;
    background-size: 30%;
  }
  .OBffARBa {
    position: absolute;
    margin-top: -58px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .KHI1MLCN .nI5tlmHH {
    height: 116px;
    background-size: 34%;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .KHI1MLCN .nI5tlmHH {
    height: 126px;
    background-size: 37%;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1800px) {
  .KHI1MLCN .nI5tlmHH {
    height: 136px;
    background-size: 38%;
  }
}
.ruIqSl5d {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  pointer-events: none;
}
.KHI1MLCN .ant-carousel,
.KHI1MLCN .slick-slider,
.KHI1MLCN .slick-list,
.KHI1MLCN .slick-track {
  height: 100%;
}
.KHI1MLCN .slick-dots button {
  position: relative;
}
.KHI1MLCN .slick-dots button::after {
  position: absolute;
  content: '';
  inset: -4px;
}
.__6wcka4OD .wGR1P2kD {
  margin-top: 30px;
  font-weight: bold;
  font-size: 30px;
}
.__6wcka4OD .wGR1P2kD p {
  margin-bottom: 0;
}
.__6wcka4OD .wGR1P2kD span {
  color: #1A66FF;
}
.__6wcka4OD .SeiAPDHr {
  display: flex;
  align-items: center;
  margin-top: 50px;
}
.__6wcka4OD .SeiAPDHr .VRnRSLS0 {
  margin-top: 0 !important;
}
.__6wcka4OD .nI5tlmHH {
  position: relative;
}
.__6wcka4OD .z3UijOaE {
  position: absolute;
  top: calc(50% - 100px - 40px);
  right: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 460px;
  height: 304px;
  overflow: hidden;
}
.__6wcka4OD .z3UijOaE img {
  position: relative;
  z-index: 2;
}
.__6wcka4OD .z3UijOaE::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('/images/market/bannerfloatimg@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 0.3;
  content: '';
}
.__6wcka4OD .z3UijOaE::after {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 3;
  width: 420px;
  height: 2px;
  background: linear-gradient(to right, rgba(26, 102, 255, 0.2) 0%, #1a66ff 50%, rgba(26, 102, 255, 0.2) 100%);
  box-shadow: 0 -5px 10px 0 #1A66FF;
  animation: __96pVi4Xl 3s linear infinite;
  content: '';
}
@keyframes __96pVi4Xl {
  0% {
    top: 0%;
  }
  100% {
    top: 120%;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.tXLH0SAx {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 380px;
  height: 176px;
  padding: 30px 30px 12px;
  background: #fff;
  background-color: #fff;
  background-size: cover;
  border-radius: 4px;
  box-shadow: 0 6px 20px 0 rgba(134, 138, 156, 0.3);
  cursor: pointer;
}
.tXLH0SAx h2 {
  margin-bottom: 16px;
  color: #030a1a;
  font-size: 17px;
  line-height: 18px;
}
.tXLH0SAx .f2fB_0px {
  margin-top: 8px;
}
.tXLH0SAx .TXbpF3uH {
  flex: 1 1;
  color: #858c99;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
}
.tXLH0SAx .TXbpF3uH p {
  margin: 0;
}
.tXLH0SAx .__5yPhrktb {
  width: 96px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  display: block;
  color: #1A66FF;
  background: #fff;
  opacity: 0;
}
.tXLH0SAx .__5yPhrktb:hover {
  opacity: 0.8;
}
.tXLH0SAx .etJqLWRr {
  width: 96px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  display: block;
  color: #fff;
  line-height: 30px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 2px;
  opacity: 0;
}
.tXLH0SAx .etJqLWRr:hover {
  color: #1A66FF;
  background-color: #fff;
  border-color: #fff;
}
.tXLH0SAx > :not(.cnqQThkE) {
  position: relative;
  z-index: 1;
}
.tXLH0SAx .cnqQThkE {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.3s linear;
}
.tXLH0SAx,
.tXLH0SAx .TXbpF3uH,
.tXLH0SAx h2,
.tXLH0SAx .etJqLWRr,
.tXLH0SAx .__5yPhrktb {
  transition: all 0.3s linear;
}
.tXLH0SAx:hover {
  background-color: #1A66FF;
  background-repeat: no-repeat;
}
.tXLH0SAx:hover .TXbpF3uH,
.tXLH0SAx:hover h2 {
  color: #fff;
}
.tXLH0SAx:hover .etJqLWRr,
.tXLH0SAx:hover .__5yPhrktb {
  opacity: 1;
  transition: all 0.3s linear;
}
.tXLH0SAx:hover .cnqQThkE {
  opacity: 1;
  transition: opacity 0.3s linear 0.3s;
}
.y0lwumEe {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 458px;
  margin-right: 8px;
  padding: 30px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 12px 12px 20px 0 rgba(134, 138, 156, 0.15);
  cursor: pointer;
}
.y0lwumEe .__98ZPYIqw {
  width: 100%;
  height: 16px;
  margin-bottom: 20px;
  color: #1a66ff;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}
.y0lwumEe ._3suewms {
  display: flex;
  flex: 0 0 170px;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #e6e8ec;
}
.y0lwumEe ._3suewms img {
  max-width: 100%;
  max-height: 100%;
}
.y0lwumEe .hGBkpBKF {
  flex: 1 1 auto;
  margin-top: 30px;
}
.y0lwumEe .lCjYPwg1 {
  margin-bottom: 16px;
  color: #030a1a;
  font-size: 18px;
  line-height: 24px;
}
.y0lwumEe .__1KeIYH0n {
  flex-wrap: nowrap;
  margin-bottom: 8px;
  color: #858c99;
  font-size: 14px;
  line-height: 20px;
}
.y0lwumEe .__1KeIYH0n .z2zmzF0D {
  height: 30px;
  padding: 6px 11px;
  color: #858c99;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
  border: 1px solid #f2f5fa;
  border-radius: 2px;
}
.y0lwumEe .TXbpF3uH {
  position: absolute;
  right: 30px;
  bottom: 30px;
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: flex-end;
  width: 100px;
}
.y0lwumEe .__5yPhrktb {
  width: 96px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: #fff;
  background-color: #1A66FF;
  transition: 0.3s;
}
.y0lwumEe .__5yPhrktb:hover {
  opacity: 0.8;
}
.y0lwumEe .etJqLWRr {
  width: 96px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: #030a1a;
  line-height: 30px;
  background-color: #fff;
  border: 1px solid #dcdfe5;
  transition: 0.3s;
}
.y0lwumEe .etJqLWRr:hover {
  color: #1A66FF;
  border-color: #1A66FF;
}
.y0lwumEe .Rec0YCQH {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 4px;
  background: #1a66ff;
  border-radius: 0 0 4px 4px;
  opacity: 0;
}
.y0lwumEe:hover {
  background: #fff;
  border-radius: 4px;
  box-shadow: 12px 12px 30px 0 rgba(134, 138, 156, 0.3);
}
.y0lwumEe:hover .lCjYPwg1 {
  color: #1A66FF;
}
.y0lwumEe:hover .Rec0YCQH {
  opacity: 1;
  transition: opacity 0.5s linear;
}
.iGnmBlGx {
  width: 96px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.RH8hT66G {
  height: 720px;
  background-color: #f2f5fa;
}
.RH8hT66G .ABEfzllA h1 {
  margin-top: 72px;
}
.RH8hT66G .ABEfzllA .__17G_JOcn {
  color: #1A66FF;
}
.RH8hT66G .ABEfzllA .__17G_JOcn:hover {
  text-decoration: underline;
}
.RH8hT66G .ABEfzllA .__17G_JOcn:hover + .H7y_nGGC {
  transform: translateX(4px);
}
.RH8hT66G .ABEfzllA .H7y_nGGC {
  margin-left: 8px;
  color: #1A66FF;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.uR9ue5VW {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 72px;
}
.uR9ue5VW .iMVBbJta {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 30px 40px 60px 0;
}
.uR9ue5VW .iMVBbJta .xehAZp_a {
  flex: 1 1;
}
.uR9ue5VW .iMVBbJta .__129NV3ug {
  padding-top: 30px;
  padding-bottom: 16px;
  color: #030a1a;
  font-size: 20px;
}
.uR9ue5VW .iMVBbJta p {
  color: #858c99;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: justify;
}
.uR9ue5VW .uqqG_pVc {
  position: relative;
  width: 754px;
  height: 423px;
}
.uR9ue5VW .uqqG_pVc video {
  width: 100%;
}
.uR9ue5VW .kxQcCr7G {
  display: inline-block;
  width: 96px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: #030a1a;
  line-height: 30px;
  background-color: #fff;
  border: 1px solid #dcdfe5;
  transition: 0.3s;
}
.uR9ue5VW .kxQcCr7G:hover {
  color: #1A66FF;
  border-color: #1A66FF;
}
.uR9ue5VW .Xu2YyIg3 {
  display: inline-block;
  width: 96px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: #fff;
  background: #1a66ff;
  transition: 0.3s;
}
.uR9ue5VW .Xu2YyIg3:hover {
  opacity: 0.8;
}
.uR9ue5VW .TQfUe_Ie {
  display: inline-block;
  width: 96px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
}

.Tabs_tab-bar-wrapper__9yM93{display:flex}.Tabs_left-line__dz9Cf{border-bottom:1px solid #dcdfe5;flex:1 1}.Tabs_right-line__EeTZh{border-bottom:1px solid #dcdfe5;flex:1 1}.Tabs_tab-pane-header__tgWaa{border-bottom:1px solid #dcdfe5}.Tabs_tab-pane__b0wS1{flex:1 1;padding-bottom:18px;color:#030a1a;font-size:18px;text-align:center;cursor:pointer}.Tabs_tab-pane-active__FTDMt{color:#1a66ff;font-weight:bold}.Tabs_active-ink-bar__B3S0i{position:absolute;bottom:-1px;height:2px;background-color:#1a66ff;transform:translateX(0);transition:all .2s ease}.Tabs_tab-content__H2_lY{margin-top:40px}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.__0MZXEB4a {
  min-height: 733px;
  background-color: #fff;
}
.__0MZXEB4a h1 {
  margin-top: 72px;
}
.__0MZXEB4a .USBSAXfI {
  display: flex;
  flex-basis: 1180px;
  width: 1180px;
}
.__0MZXEB4a .USBSAXfI div {
  flex: 1 1;
  padding-bottom: 18px;
  text-align: center;
  border-bottom: 1px solid #dcdfe5;
}
.__0MZXEB4a .USBSAXfI div span {
  padding: 0 10px;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
}
.__0MZXEB4a .USBSAXfI .__892sDLAA {
  border-color: #1A66FF;
  border-width: 2px;
}
.__0MZXEB4a .USBSAXfI .__892sDLAA span {
  color: #1A66FF;
  font-weight: bold;
}
.K2nZPvIQ {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.K2nZPvIQ .geXZa8_s {
  flex: 1 1;
  border-bottom: 1px solid #dcdfe5;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.MIabUpZP {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 295px;
  height: 500px;
  padding: 0 30px 40px;
  background-color: #061528;
  background-image: url('/images/home/method_pic_default@2x.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.MIabUpZP .nX99R_t6 {
  position: relative;
  z-index: 1;
}
.MIabUpZP .nX99R_t6 h2 {
  color: #fff;
  font-size: 20px;
}
.MIabUpZP .nX99R_t6 p {
  min-height: 60px;
  margin-top: 22px;
  margin-bottom: 34px;
  color: #c0c4cc;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
}
.MIabUpZP .pq9cF12_ {
  position: relative;
  height: 40px;
}
.MIabUpZP .pq9cF12_ > div {
  opacity: 1;
  transition: opacity 0s linear 0.3s;
}
.MIabUpZP .fGeZJYOc {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 235px;
  height: 40px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  background: #1a66ff;
  border-radius: 2px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s linear;
}
.MIabUpZP .UKBJ_ufd {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.3s linear;
}
.MIabUpZP.hY59kAvC .fGeZJYOc,
.MIabUpZP:hover .fGeZJYOc,
.MIabUpZP.hY59kAvC .UKBJ_ufd,
.MIabUpZP:hover .UKBJ_ufd {
  opacity: 1;
}
.MIabUpZP.hY59kAvC .fGeZJYOc,
.MIabUpZP:hover .fGeZJYOc {
  z-index: 1;
}
.MIabUpZP.hY59kAvC .fGeZJYOc:hover,
.MIabUpZP:hover .fGeZJYOc:hover {
  opacity: 0.8;
}
.MIabUpZP.hY59kAvC .pq9cF12_ > div,
.MIabUpZP:hover .pq9cF12_ > div {
  opacity: 0;
  transition: none;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.X8DOhd00 {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 766px;
}
.X8DOhd00 .__6ZV5UE5b {
  position: absolute;
  height: 100%;
  background-color: #1A66FF;
}
.X8DOhd00 .l1d2K8QO {
  position: absolute;
  right: 0;
  height: 100%;
  background-image: url('/images/home/method_bg@2x.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.X8DOhd00 h1 {
  margin-top: 72px;
}
.X8DOhd00 p {
  color: #858c99;
  font-size: 16px;
  line-height: 16px;
}
.X8DOhd00 .AvEyXwB_ {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.CIpqSmOm {
  height: 582px;
  background: #fff;
}
.CIpqSmOm h1 {
  margin-top: 72px;
}
.CIpqSmOm p {
  color: #858c99;
  font-size: 16px;
  line-height: 16px;
}
.CIpqSmOm .xQCAUcXo {
  position: relative;
  width: 187px;
  height: 90px;
  margin-bottom: 12px;
  padding: 11px 10px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 6px 4px 12px 0 #f2f5fa;
}
.CIpqSmOm .xQCAUcXo:hover {
  box-shadow: 6px 4px 12px 0 rgba(220, 223, 229, 0.6);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ofjzgSc4 {
  position: relative;
  width: 100%;
  height: 226px;
}
.ofjzgSc4.__3a9cJde1 .NRe63rcU {
  background-color: #fff;
}
.ofjzgSc4.__3a9cJde1 .NsP0lhyP .GW2pgYmU {
  color: #858c99;
}
.ofjzgSc4 .NRe63rcU {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #1A66FF;
}
.ofjzgSc4 .__5g72CPpd {
  position: relative;
  width: 1180px;
  height: 100%;
}
.ofjzgSc4 .__0HI_qddE {
  padding-top: 47px;
}
.ofjzgSc4 .NsP0lhyP {
  display: flex;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 30px;
}
.ofjzgSc4 .NsP0lhyP .GW2pgYmU {
  margin-left: 8px;
  color: #81a8f9;
  font-size: 16px;
  line-height: 20px;
}
.ofjzgSc4 .useBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 40px;
  font-size: 16px;
  border-radius: 2px;
  cursor: pointer;
  color: #1a66ff;
  background-color: #fff;
  transition: 0.3s;
}
.ofjzgSc4 .useBtn:hover {
  opacity: 0.8;
}
.ofjzgSc4 .contactBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 40px;
  font-size: 16px;
  border-radius: 2px;
  cursor: pointer;
  color: #fff;
  border: 1px solid #dcdfe5;
  transition: 0.3s;
}
.ofjzgSc4 .contactBtn:hover {
  color: #1A66FF;
  background-color: #fff;
}
.ofjzgSc4 .blueBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 40px;
  font-size: 16px;
  border-radius: 2px;
  cursor: pointer;
  color: #fff;
  background-color: #1A66FF;
}
.ofjzgSc4 .blueBtn:hover {
  opacity: 0.8;
}
.ofjzgSc4 .IrbvfcnB {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 40px;
  font-size: 16px;
  border-radius: 2px;
  cursor: pointer;
}

.WechatBanner_container__tNChA{width:100%;height:160px;background-image:url("/images/home/guide_bg@2x.png");background-size:100% 100%}.WechatBanner_content__FrAhx{display:flex;align-items:center;justify-content:center;width:1180px;max-width:1180px;margin:auto}.WechatBanner_content__FrAhx .WechatBanner_leftContent__r38Od .WechatBanner_title__5sGhQ{margin-bottom:16px;color:#fff;font-weight:bold;font-size:24px;white-space:nowrap}.WechatBanner_content__FrAhx .WechatBanner_leftContent__r38Od .WechatBanner_desc__7uEx6{color:#fff;font-weight:500;font-size:16px}.WechatBanner_content__FrAhx .WechatBanner_qrCode__rcHUp{display:flex;flex-direction:column;align-items:center}.WechatBanner_content__FrAhx .WechatBanner_qrCode__rcHUp .WechatBanner_qrCodeText__8_vDM{margin-top:6px;color:#fff;font-weight:bold;font-size:14px;white-space:nowrap}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #030a1a;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.ant-back-top:empty {
  display: none;
}
.ant-back-top-rtl {
  right: auto;
  left: 100px;
  direction: rtl;
}
.ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  transition: all 0.3s;
}
.ant-back-top-content:hover {
  background-color: #030a1a;
  transition: all 0.3s;
}
.ant-back-top-icon {
  font-size: 24px;
  line-height: 40px;
}
@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px;
  }
}
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.MhjEzw4C {
  position: fixed;
  right: 0;
  bottom: 80px;
  z-index: 600;
}
.MhjEzw4C .__0k2WFzR2 {
  background: linear-gradient(138deg, #e5eaf2 0%, #f5f8fc 100%);
  border: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  box-shadow: 0 4px 12px 0 rgba(6, 6, 26, 0.1), 0 0 20px -15px rgba(19, 18, 60, 0.14);
}
.MhjEzw4C .__36eKV7LN {
  background: linear-gradient(138deg, #e5eaf2 0%, #f5f8fc 100%);
  border: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  box-shadow: 0 4px 12px 0 rgba(6, 6, 26, 0.1), 0 0 20px -15px rgba(19, 18, 60, 0.14);
}
.MhjEzw4C .__0sBiLXPC {
  width: 48px;
  height: 120px;
  background: linear-gradient(360deg, #1a66ff 0%, #389fff 100%);
  border-radius: 4px 0 0 4px;
  box-shadow: 4px 4px 8px 0 rgba(3, 10, 26, 0.1), -4px -4px 8px 0 rgba(255, 255, 255, 0.08), 0 4px 12px 0 rgba(6, 6, 26, 0.1), 0 0 20px -15px rgba(19, 18, 60, 0.14), inset 0 0 3px 0 rgba(255, 255, 255, 0.5);
}
.MhjEzw4C .__6kvHjYWy {
  width: 48px;
  height: 120px;
  overflow: hidden;
  background: linear-gradient(315deg, rgba(255, 255, 255, 0), #bbd4fe);
  border-radius: 4px 0 0 4px;
}
.MhjEzw4C .__6kvHjYWy .ZaKrbGJG {
  width: 47px;
  height: 118px;
  margin: 1px;
  padding: 16px;
  background: linear-gradient(360deg, #1a66ff 0%, #389fff 100%);
  border-radius: 4px 0 0 4px;
}
.MhjEzw4C .__6kvHjYWy .ZaKrbGJG p {
  width: 16px;
  height: 88px;
  margin: 0;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-shadow: 4px 4px 8px rgba(3, 10, 26, 0.1), -4px -4px 8px rgba(255, 255, 255, 0.08);
}
.MhjEzw4C .G2wGDqWT {
  position: absolute;
  top: 50px;
  right: 0;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 rgba(6, 6, 26, 0.1), 0 0 20px -15px rgba(19, 18, 60, 0.14);
}
.MhjEzw4C .VWRVFhu4 {
  position: absolute;
  top: -100px;
  right: 0;
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
}
.MhjEzw4C .VWRVFhu4 .__9t8n3CZH {
  position: absolute;
  top: 1px;
  right: 1px;
  z-index: 1;
  display: block;
  width: 10px;
  height: 10px;
}
.MhjEzw4C .CL7Rr_PD {
  margin-bottom: 12px;
  overflow: hidden;
  border-radius: 4px 0 0 4px;
}
.MhjEzw4C .CL7Rr_PD > *:last-child {
  margin-bottom: 0 !important;
}
.MhjEzw4C .CL7Rr_PD .p5oECPtB {
  background: linear-gradient(138deg, #e5eaf2 0%, #f5f8fc 100%);
  border: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  box-shadow: 0 4px 12px 0 rgba(6, 6, 26, 0.1), 0 0 20px -15px rgba(19, 18, 60, 0.14);
  position: relative;
  width: 48px;
  height: 48px;
  margin-bottom: 12px;
  box-shadow: none;
  cursor: pointer;
}
.MhjEzw4C .CL7Rr_PD .p5oECPtB .jQiK_Ahf {
  margin: 6px auto 0;
}
.MhjEzw4C .CL7Rr_PD .p5oECPtB .UT2tLGEb {
  position: absolute;
  right: -1px;
  bottom: -1px;
  left: -1px;
  height: 15px;
  background: linear-gradient(360deg, #1a66ff 0%, #389fff 100%);
  border-radius: 0 0 0 4px;
}
.MhjEzw4C .CL7Rr_PD .p5oECPtB .UT2tLGEb span {
  display: block;
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
  transform: scale(0.83);
  color: #fff;
  line-height: 15px;
}
.MhjEzw4C .CL7Rr_PD .p5oECPtB .UT2tLGEb span.uVqrv7Ie {
  padding-left: 2px;
  font-weight: normal;
  transform: scale3d(0.75, 0.83, 1);
  transform-origin: left center;
}
.MhjEzw4C .CL7Rr_PD .FxOTCSQw {
  background: linear-gradient(138deg, #e5eaf2 0%, #f5f8fc 100%);
  border: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  box-shadow: 0 4px 12px 0 rgba(6, 6, 26, 0.1), 0 0 20px -15px rgba(19, 18, 60, 0.14);
  position: relative;
  width: 48px;
  height: 48px;
  box-shadow: none;
  cursor: pointer;
}
.MhjEzw4C .CL7Rr_PD .FxOTCSQw .jQiK_Ahf {
  margin: 6px auto 0;
}
.MhjEzw4C .CL7Rr_PD .FxOTCSQw:hover .jQiK_Ahf {
  animation: __3MTsqLTz 1.6s;
}
@keyframes __3MTsqLTz {
  0% {
    transform: translate3d(0, 0, 0) scale(1) rotateZ(0);
  }
  25% {
    transform: translate3d(10px, -50px, 0) scale(0.5) rotateZ(0);
  }
  47% {
    transform: translate3d(0, 0, 0) scale(1) rotateZ(180deg);
  }
  50% {
    transform: translate3d(0, 0, 0) scale(1) rotateZ(180deg);
  }
  65% {
    transform: translate3d(-20px, 30px, 0) scale(1.2) rotateZ(180deg);
  }
  90% {
    transform: translate3d(-20px, 30px, 0) scale(1.2) rotateZ(0);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1) rotateZ(0);
  }
}
.MhjEzw4C .CL7Rr_PD .FxOTCSQw .UT2tLGEb {
  position: absolute;
  right: -1px;
  bottom: -1px;
  left: -1px;
  height: 15px;
  background: linear-gradient(360deg, #1a66ff 0%, #389fff 100%);
  border-radius: 0 0 0 4px;
}
.MhjEzw4C .CL7Rr_PD .FxOTCSQw .UT2tLGEb span {
  display: block;
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
  transform: scale(0.83);
  color: #fff;
  line-height: 15px;
}
.MhjEzw4C ._lGN4Def {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  cursor: pointer;
}
.MhjEzw4C ._lGN4Def .Kpu30OYl {
  position: absolute;
  top: 0;
  right: -1px;
  display: flex;
  align-items: center;
  width: 0;
  height: 48px;
  padding: 12px 0;
  overflow: hidden;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;
  background-color: #1A66FF;
  border-radius: 4px 0 0 4px;
  box-shadow: 4px 4px 8px 0 rgba(3, 10, 26, 0.1), -4px -4px 8px 0 rgba(255, 255, 255, 0.08);
  transition: width 0.4s;
}
.MhjEzw4C ._lGN4Def .Kpu30OYl .usvBZZFS {
  padding-right: 20px;
  padding-left: 8px;
}
.MhjEzw4C ._lGN4Def .Kpu30OYl .anticon {
  margin-left: 12px;
  font-size: 24px;
}
.MhjEzw4C ._lGN4Def:first-child .Kpu30OYl {
  top: -1px;
}
.MhjEzw4C ._lGN4Def:hover .Kpu30OYl {
  width: 120px;
}
.MhjEzw4C ._lGN4Def.__2bGlnay8:hover .Kpu30OYl {
  width: 164px;
}
.MhjEzw4C ._lGN4Def:not(:last-child)::before {
  position: absolute;
  right: 12px;
  bottom: 0;
  left: 12px;
  height: 1px;
  background-color: #DCDFE5;
  content: '';
}
.MhjEzw4C .KoYQNw9i {
  position: relative;
}
.MhjEzw4C .KoYQNw9i::after {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation: FEKM_2vQ 1.5s infinite;
  content: '';
}
@keyframes FEKM_2vQ {
  0% {
    box-shadow: 0 0 0 0 rgba(229, 82, 69, 0.7);
    opacity: 1;
  }
  70% {
    box-shadow: 0 0 0 8px rgba(229, 82, 69, 0.7);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.MhjEzw4C .__2bGlnay8 {
  cursor: default;
}
.MhjEzw4C .__2bGlnay8 .usvBZZFS {
  font-size: 22px;
  font-family: 'CondensedBold';
}
.MhjEzw4C .__2bGlnay8 .usvBZZFS .l0g7OW2h {
  opacity: 0.5;
}
.MhjEzw4C .__4FwhYNFR {
  background: linear-gradient(138deg, #e5eaf2 0%, #f5f8fc 100%);
  border: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  box-shadow: 0 4px 12px 0 rgba(6, 6, 26, 0.1), 0 0 20px -15px rgba(19, 18, 60, 0.14);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  cursor: pointer;
}
.MhjEzw4C .__4FwhYNFR .jQiK_Ahf .anticon {
  color: #475266;
}
.MhjEzw4C .__4FwhYNFR:hover .jQiK_Ahf .anticon {
  color: #1A66FF;
}
.jQiK_Ahf {
  position: relative;
  width: 24px;
  height: 24px;
}
.jQiK_Ahf .anticon {
  color: #1A66FF;
  font-size: 24px;
}
.u6AbdtHj {
  display: block;
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
  transform: scale(0.83);
}
.ue7KQi7O .ant-popover-inner-content {
  padding: 0;
  background-color: #fff;
  border-radius: 4px;
}
.ue7KQi7O .ant-popover-inner {
  border-radius: 4px;
  box-shadow: none;
}
.ue7KQi7O .ant-popover-content {
  border-radius: 4px;
}
.ue7KQi7O .dMYq7_ft {
  width: 280px;
  padding: 11px 0;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 6px 18px 0 rgba(3, 10, 26, 0.12);
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl {
  position: relative;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .pP_WVNkA {
  width: 100%;
  height: 60px;
  padding: 10px 0 10px 20px;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .pP_WVNkA .KoYQNw9i {
  width: 40px;
  height: 40px;
  background-image: url('/images/bridge/icon-circle-message.png');
  background-size: 40px 40px;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .pP_WVNkA .X62WyE7W {
  position: absolute;
  right: 0;
  bottom: 2px;
  width: 10px;
  height: 10px;
  background: #1a66ff;
  border: 2px solid #fff;
  border-radius: 10px;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .ZiW8hFUU {
  width: 100%;
  height: 60px;
  padding: 10px 0 10px 20px;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .ZiW8hFUU .K6aiJ6tZ {
  width: 40px;
  height: 40px;
  background-image: url('/images/bridge/icon-phone-default.svg');
  background-size: 40px 40px;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .b3gaje5O {
  width: 100%;
  height: 72px;
  padding: 10px 0 10px 20px;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .b3gaje5O .BsvzQLgT {
  width: 40px;
  height: 40px;
  background-image: url('/images/bridge/icon-message-default.svg');
  background-size: 40px 40px;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .jQiK_Ahf {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 12px;
  background: #f2f5fa;
  border-radius: 50%;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .jQiK_Ahf .anticon {
  color: #1A66FF;
  font-size: 24px;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .Hz6QAvKj {
  position: absolute;
  display: inline-block;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .Hz6QAvKj .__1D4F96DO {
  display: block;
  width: 84px;
  height: 20px;
  color: #030a1a;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .Hz6QAvKj .usvBZZFS {
  display: flex;
  width: 100%;
  height: 17px;
  color: #858c99;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .Hz6QAvKj .AX9J3fIc {
  width: 11px;
  white-space: nowrap;
}
.ue7KQi7O .dMYq7_ft .pP_WVNkA:hover {
  background: linear-gradient(270deg, #fff 0%, #e9f0ff 100%);
  cursor: pointer;
}
.ue7KQi7O .dMYq7_ft .pP_WVNkA:hover .KoYQNw9i {
  background-image: url('/images/bridge/icon-circle-message-hover.png');
  transition-duration: 0.5s;
}
.ue7KQi7O .dMYq7_ft .pP_WVNkA:hover .usvBZZFS {
  color: #1a66ff;
}
.ue7KQi7O .dMYq7_ft .ZiW8hFUU:hover {
  background: linear-gradient(270deg, #fff 0%, #e9f0ff 100%);
  cursor: pointer;
}
.ue7KQi7O .dMYq7_ft .ZiW8hFUU:hover .K6aiJ6tZ {
  background-image: url('/images/bridge/icon-phone-hover.svg');
  transition-duration: 0.5s;
}
.ue7KQi7O .dMYq7_ft .ZiW8hFUU:hover .usvBZZFS {
  color: #1a66ff;
}
.ue7KQi7O .dMYq7_ft .b3gaje5O:hover {
  background: linear-gradient(270deg, #fff 0%, #e9f0ff 100%);
  cursor: pointer;
}
.ue7KQi7O .dMYq7_ft .b3gaje5O:hover .BsvzQLgT {
  background-image: url('/images/bridge/icon-message-hover.svg');
  transition-duration: 0.5s;
}
.ue7KQi7O .dMYq7_ft .b3gaje5O:hover .usvBZZFS {
  color: #1a66ff;
}
.lK3KipT1 .ant-popover-inner {
  border-radius: 4px;
  box-shadow: 0 10px 32px 0 rgba(3, 10, 26, 0.16);
}
.lK3KipT1 .ant-popover-inner-content {
  padding: 0;
}
.lK3KipT1 .FNrewtUl {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 260px;
  padding: 40px 20px;
  white-space: nowrap;
  text-align: center;
}
.lK3KipT1 .FNrewtUl .__03h_72N6 {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 16px;
  height: 16px;
  color: #858c99;
  cursor: pointer;
  transition: 0.3s;
}
.lK3KipT1 .FNrewtUl .__03h_72N6:hover {
  opacity: 0.8;
}
.lK3KipT1 .FNrewtUl .I6XAUiJa {
  margin-bottom: 10px;
  color: #030a1a;
  font-weight: bold;
  font-size: 16px;
}
.lK3KipT1 .FNrewtUl .q0abEev6 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 136px;
  background-color: rgba(242, 245, 250, 0.5);
  border-radius: 8px;
}
.lK3KipT1 .FNrewtUl .q0abEev6 > span {
  box-shadow: 0 0 20px 0 rgba(19, 18, 60, 0.14);
}
.lK3KipT1 .FNrewtUl .__9d_VICg8 {
  width: 112px;
  height: 112px;
  margin: 12px 0 0 12px;
}
.lK3KipT1 .FNrewtUl .mWcvDc_h {
  margin: 10px 0 20px;
  color: #030a1a;
  font-weight: 500;
  font-size: 12px;
}
.lK3KipT1 .FNrewtUl .gpt4rm9E {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 8px;
       column-gap: 8px;
  row-gap: 8px;
}
.lK3KipT1 .FNrewtUl .TUj6tlzp {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 106px;
  height: 40px;
  color: #030a1a;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background-image: url('/images/qrcode/gift_item_bg.png');
  background-size: 100%;
}
.lK3KipT1 .FNrewtUl .TUj6tlzp div {
  flex: 1 1 auto;
  line-height: 20px;
  text-align: center;
  background: #e9f0ff;
  border-radius: 3px;
}
.lK3KipT1 .FNrewtUl .TUj6tlzp div span {
  transform: scale(0.93);
}
.lK3KipT1 .FNrewtUl .TUj6tlzp div:last-child {
  margin-left: 12px;
}
.ivGjEeBV {
  position: absolute;
  top: 80px;
  right: 100%;
  width: 246px;
  height: 130px;
}
.ivGjEeBV .__5lyeZweA {
  position: absolute;
  bottom: 24px;
  left: 17px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  text-shadow: 0 0 12px rgba(26, 102, 255, 0.5);
}

.PortraitsEntry_entry__XMO49{margin-bottom:12px;display:flex;align-items:center;justify-content:center;width:48px;height:48px;color:#fff;font-weight:600;font-size:16px;line-height:18px;text-align:center;background:linear-gradient(0deg, #1a66ff 0%, #389fff 100%);border:1px solid #1a66ff;border-radius:4px 0 0 4px;box-shadow:none;cursor:pointer}.PortraitsEntry_entryPopover__Hi9xr .ant-popover-inner{border-radius:4px;box-shadow:0 10px 32px 0 rgba(3,10,26,.16)}.PortraitsEntry_popoverContainer__ZQbuh{position:relative;padding-top:4px;cursor:pointer}.PortraitsEntry_popoverContainer__ZQbuh .PortraitsEntry_closeIcon__4aLC8{position:absolute;top:-4px;right:-8px;color:#999;cursor:pointer}.PortraitsEntry_title__rCo0o{font-weight:400;font-size:16px;text-align:center}.PortraitsEntry_titleStrong__4CAGt{margin:8px 4px 0;padding:4px 8px;color:#fff;font-weight:500;font-size:16px;background-color:#1a66ff;border-radius:4px}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.sJxKNwbt {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10003;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(3, 10, 26, 0.6);
}
.sJxKNwbt .VmqtaOY_ {
  position: relative;
  width: 506px;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 20px 40px 0 rgba(3, 10, 26, 0.2);
}
.sJxKNwbt .VmqtaOY_ .o14QLmf4 {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
}
.sJxKNwbt .VmqtaOY_ .o14QLmf4 .warn {
  height: 24px;
  margin-right: 8px;
}
.sJxKNwbt .VmqtaOY_ .o14QLmf4 .title {
  color: #030a1a;
  font-weight: bold;
  font-size: 16px;
  line-height: 14px;
  white-space: nowrap;
}
.sJxKNwbt .VmqtaOY_ .xE7YuNCg {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;
}
.sJxKNwbt .VmqtaOY_ .xE7YuNCg .dkHMkggz {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 86px;
}
.sJxKNwbt .VmqtaOY_ .xE7YuNCg .dkHMkggz .xVsBYmiW {
  position: relative;
  width: 44px;
  height: 46px;
  margin-bottom: 16px;
}
.sJxKNwbt .VmqtaOY_ .xE7YuNCg .dkHMkggz .Ul1HilZE {
  position: relative;
  color: #030a1a;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
}
.sJxKNwbt .VmqtaOY_ .xE7YuNCg .dkHMkggz .Q598zSNB {
  position: relative;
  color: #ef6463;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
}
.sJxKNwbt .VmqtaOY_ .B15UXsjs {
  margin-top: 20px;
  text-align: right;
}
.sJxKNwbt .VmqtaOY_ .B15UXsjs button {
  padding: 4px 12px;
}

.TopBanner_container__qjY2A{width:100%;min-width:1180px;height:40px}.TopBanner_container__qjY2A .TopBanner_banner___xbCR{position:fixed;z-index:100;display:flex;align-items:center;justify-content:center;width:100%;min-width:1180px;height:40px;background-image:url("/images/banner/top_banner_bg.png");background-size:100% 100%;cursor:pointer}.TopBanner_container__qjY2A .TopBanner_banner___xbCR .TopBanner_label1__LFagd{color:#fff;font-weight:bold;font-size:24px;line-height:36px;background:linear-gradient(to right, #ffefd4 0%, #ffd7a2 100%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:transparent}.TopBanner_container__qjY2A .TopBanner_banner___xbCR .TopBanner_label2__jZRoS{min-width:400px;color:#fff;font-weight:500;font-size:20px;line-height:32px;white-space:nowrap;text-align:center;text-shadow:0 2px 4px #0154d5}.TopBanner_container__qjY2A .TopBanner_banner___xbCR .TopBanner_btn__uuoT2{display:flex;align-items:center;justify-content:center;width:118px;height:32px;margin-left:12px;background:linear-gradient(205deg, #fff2d6 0%, #fff2eb 100%);border:2px solid #fff2eb;border-radius:4px;cursor:pointer}.TopBanner_container__qjY2A .TopBanner_banner___xbCR .TopBanner_btn__uuoT2 .TopBanner_btnText__56iBw{color:#e66d16;font-weight:600;font-size:15px;line-height:16px}.TopBanner_container__qjY2A .TopBanner_banner___xbCR .TopBanner_btn__uuoT2 .TopBanner_arrow__vslvX{margin-left:4px}.TopBanner_container__qjY2A .TopBanner_banner___xbCR .TopBanner_closeIconWrapper__ONPrA{position:absolute;right:18px;display:flex;align-items:center;justify-content:center;width:22px;height:22px;background:rgba(255,255,255,.2);border-radius:20px;cursor:pointer}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.y1fU5arT {
  padding-top: 58px;
}

