.tab-bar-wrapper{
    display: flex;
  }
  .left-line{
    border-bottom: 1px solid #dcdfe5;
    flex: 1;
  }
  .right-line{
    border-bottom: 1px solid #dcdfe5;
    flex: 1;
  }
  .tab-pane-header {
    border-bottom: 1px solid #dcdfe5;
  }
  .tab-pane {
    flex: 1;
    padding-bottom: 18px;
    color: #030a1a;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    
  }
  
  .tab-pane-active {
    color: #1a66ff;
    font-weight: bold;
  }
  .active-ink-bar {
    position: absolute;
    bottom: -1px;
    height: 2px;
    background-color: #1a66ff;
    transform: translateX(0);
    transition: all 0.2s ease;
  }
  .tab-content{
      margin-top: 40px;
  }